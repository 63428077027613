
import {
  defineComponent,
  onActivated,
  onMounted,
  reactive,
  ref,
  watch,
  toRaw,
} from "vue";
import { ElMessage } from "element-plus";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { getMyCourseWare, getMyCourseWareSearch } from "@/services";
import ShowResource from "@/components/showResource";
import { usePageTableContext } from "@evideo/frontend-utils";
import pageTableContextMap from "@/utils/page-table-context-map";
import _ from "lodash";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import logger from "@evideo/logger";

export default defineComponent({
  components: {
    ShowResource,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const router = useRouter();
    let currentPage = ref(1);
    let totalPage = ref(1);
    let message = ref("");
    let searchContent = ref("");
    let reloadData = ref(false);
    let noData = ref(false);
    const showResourceRef = ref(null);
    let breadcrumbList = reactive([]);
    let teachResourceEight = reactive([]);
    let teachResourceList = reactive([]);
    let middleTeachResourceEight = reactive([]);
    let lastTeachResourceEight = reactive([]);
    let firstLoading = ref(true);
    let leftBorderFlag = ref(true);
    let rightBorderFlag = ref(true);
    let mySwiper = ref(null);
    let noDataMsg = ref("");
    let isAndroid = ref(false);
    // 判断是否是安卓设备
    const device = navigator.userAgent;
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;

    const { getContextInfo, saveContextInfo, deleteContextInfo } =
      usePageTableContext();

    // 用于保存检索条件上下文信息
    const contextInfo = ref({});

    // 获取上次页码跳转的时保存到上下文的检索参数
    const handleGetPageContext = () => {
      const context = getContextInfo(pageTableContextMap.MINE_COURSEWARE);
      logger.info("获取上下文信息", context);
      contextInfo.value = context;
      deleteContextInfo(pageTableContextMap.MINE_COURSEWARE);
    };
    const initSwiper = () => {
      mySwiper.value = document.querySelector(".swiper").swiper;
    };
    // 页面跳转前保存搜索数据
    const handleSavePageContext = () => {
      const pageContext = {
        currentPage: currentPage.value,
        breadcrumbList: toRaw(breadcrumbList),
        searchContent: searchContent.value,
      };
      logger.info("保存上下文信息", pageContext);
      saveContextInfo(pageTableContextMap.MINE_COURSEWARE, pageContext);
    };

    // 资源类型
    const bindIcon = (resource) => {
      const courseware = resource?.courseware;
      let icon;
      // 非检索时
      if (courseware || courseware === null) {
        icon = courseware?.type;
      } else {
        // 检索时
        icon = resource.type;
      }
      if (!icon) {
        return require("@/assets/img/myCourseWare/courseWare/pdf.png");
      }
      return require("@/assets/img/myCourseWare/courseWare/" + icon + ".png");
    };

    const getResourceInfo = async (params, startPage = 1) => {
      // mySwiper.value.touches.diff = 0;
      logger.info("获取我的课件资源params", params);
      logger.info("查看保存的上下文信息contextInfo===>", contextInfo.value);
      // teachResourceEight.length = 0;
      // middleTeachResourceEight.length = 0;
      // lastTeachResourceEight.length = 0;
      teachResourceList.length = 0;
      currentPage.value = 1;
      totalPage.value = 1;
      let data;
      let middleData;
      let lastData;
      // 一次性获取全部资源
      if (params?.searchContent) {
        // 有搜索关键字，获取有关数据
        getMyCourseWareSearch({
          name: params.searchContent,
          per_page: 8,
          page: startPage,
        }).then((res) => {
          if (res.data.items.length === 0) {
            noData.value = false;
          } else {
            noData.value = true;
          }
          noDataMsg.value = "暂无数据";
          // 在这里判断数据放到哪一个窗口
          if (startPage % 3 == 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 1;
          }
          if (startPage % 3 == 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 2;
          }
          if (startPage % 3 == 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 3;
          }
          // 判断结束
          if (startPage === 1 && totalPage.value !== 1) {
            leftBorderFlag.value = false;
            rightBorderFlag.value = true;
          } else if (startPage === totalPage.value && totalPage.value === 1) {
            leftBorderFlag.value = false;
            rightBorderFlag.value = false;
          } else if (startPage === totalPage.value && totalPage.value !== 1) {
            leftBorderFlag.value = true;
            rightBorderFlag.value = false;
          } else {
            leftBorderFlag.value = true;
            rightBorderFlag.value = true;
          }
          currentPage.value = startPage;
        });
        getMyCourseWareSearch({
          name: params.searchContent,
          per_page: 8,
          page: startPage + 1,
        }).then((res) => {
          // 在这里判断数据放到哪一个窗口
          if ((startPage + 1) % 3 == 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          if ((startPage + 1) % 3 == 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          if ((startPage + 1) % 3 == 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          // 判断结束
        });
        getMyCourseWareSearch({
          name: params.searchContent,
          per_page: 8,
          page: startPage - 1,
        }).then((res) => {
          // 在这里判断数据放到哪一个窗口
          if ((startPage - 1) % 3 == 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          if ((startPage - 1) % 3 == 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          if ((startPage - 1) % 3 == 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          // 判断结束
        });
      } else if (params?.id && params.id !== -1) {
        // 点击面包屑，获取相关数据
        getMyCourseWare({
          parent_id: params.id,
          per_page: 8,
          page: startPage,
        }).then((res) => {
          if (res.data.items.length === 0) {
            noData.value = false;
          } else {
            noData.value = true;
          }
          noDataMsg.value = "暂无数据";
          // 在这里判断数据放到哪一个窗口
          if (startPage % 3 == 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 1;
          }
          if (startPage % 3 == 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 2;
          }
          if (startPage % 3 == 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 3;
          }
          // 判断结束
          if (startPage === 1 && totalPage.value !== 1) {
            leftBorderFlag.value = false;
            rightBorderFlag.value = true;
          } else if (startPage === totalPage.value && totalPage.value === 1) {
            leftBorderFlag.value = false;
            rightBorderFlag.value = false;
          } else if (startPage === totalPage.value && totalPage.value !== 1) {
            leftBorderFlag.value = true;
            rightBorderFlag.value = false;
          } else {
            leftBorderFlag.value = true;
            rightBorderFlag.value = true;
          }
          currentPage.value = startPage;
        });
        getMyCourseWare({
          parent_id: params.id,
          per_page: 8,
          page: startPage + 1,
        }).then((res) => {
          // 在这里判断数据放到哪一个窗口
          if ((startPage + 1) % 3 == 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          if ((startPage + 1) % 3 == 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          if ((startPage + 1) % 3 == 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          // 判断结束
        });
        getMyCourseWare({
          parent_id: params.id,
          per_page: 8,
          page: startPage - 1,
        }).then((res) => {
          // 在这里判断数据放到哪一个窗口
          if ((startPage - 1) % 3 == 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          if ((startPage - 1) % 3 == 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          if ((startPage - 1) % 3 == 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          // 判断结束
        });
      } else {
        // 点击根节点我的课件，获取全部资源
        getMyCourseWare({
          parent_id: 0,
          per_page: 8,
          page: startPage,
        }).then((res) => {
          if (res.data.items.length === 0) {
            noData.value = false;
          } else {
            noData.value = true;
          }
          noDataMsg.value = "暂无数据";
          // 在这里判断数据放到哪一个窗口
          if (startPage % 3 == 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 1;
          }
          if (startPage % 3 == 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 2;
          }
          if (startPage % 3 == 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 3;
          }
          // 判断结束
          if (startPage === 1 && totalPage.value !== 1) {
            leftBorderFlag.value = false;
            rightBorderFlag.value = true;
          } else if (startPage === totalPage.value && totalPage.value === 1) {
            leftBorderFlag.value = false;
            rightBorderFlag.value = false;
          } else if (startPage === totalPage.value && totalPage.value !== 1) {
            leftBorderFlag.value = true;
            rightBorderFlag.value = false;
          } else {
            leftBorderFlag.value = true;
            rightBorderFlag.value = true;
          }
          currentPage.value = startPage;
        });
        getMyCourseWare({
          parent_id: 0,
          per_page: 8,
          page: startPage + 1,
        }).then((res) => {
          // 在这里判断数据放到哪一个窗口
          if ((startPage + 1) % 3 == 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          if ((startPage + 1) % 3 == 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          if ((startPage + 1) % 3 == 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          // 判断结束
        });
        getMyCourseWare({
          parent_id: 0,
          per_page: 8,
          page: startPage - 1,
        }).then((res) => {
          // 在这里判断数据放到哪一个窗口
          if ((startPage - 1) % 3 == 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          if ((startPage - 1) % 3 == 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          if ((startPage - 1) % 3 == 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...res.data.items);
            totalPage.value = res.data.meta.last_page;
          }
          // 判断结束
        });
      }
      // 兼容保留参数中页码不为1的情况
      const startSliceIndex = (startPage - 1) * 8;
      // teachResourceEight.push(...teachResourceList);
      // middleTeachResourceEight.push(...middleTeachResourceList);
      // lastTeachResourceEight.push(...lastTeachResourceList);
      // mySwiper.value.activeIndex = 1;
      // 有保存的检索分页上下文参数
      if (startPage > 1) {
        currentPage.value = startPage;
      }
      totalPage.value = data.data.meta.last_page;
      if (totalPage.value === 0) {
        totalPage.value = 1;
      }
    };
    // 面包屑点击事件
    const handleBreadcrumbClick = _.debounce((e) => {
      searchContent.value = "";
      mySwiper.value.touches.diff = 0;
      if (e.current_id === 0) {
        // 点击根节点
        getResourceInfo();
      } else {
        // 点击其他节点
        getResourceInfo({ id: e.id });
      }
      // 选择类型或搜索之后判断边界值
      if (currentPage.value == 1 && totalPage.value !== 1) {
        leftBorderFlag.value = false;
        rightBorderFlag.value = true;
      } else if ((currentPage.value == totalPage.value) == 1) {
        leftBorderFlag.value = false;
        rightBorderFlag.value = false;
      } else {
        leftBorderFlag.value = true;
        rightBorderFlag.value = true;
      }
      logger.info(
        "我在函数里判断完了",
        currentPage.value,
        totalPage.value,
        leftBorderFlag.value,
        rightBorderFlag.value
      );
      // 点击面包屑某个节点，去除这个节点后的节点
      const length = breadcrumbList.length;
      if (e.current_id + 1 < length) {
        breadcrumbList.splice(e.current_id + 1);
      }
    }, 500);
    // // 上一页
    // const handlePrevious = () => {
    //   if (currentPage.value === 1) {
    //     message.value = "已经是第一页";
    //     ElMessage.warning({
    //       offset: 200,
    //       message: message.value,
    //       type: "warning",
    //     });
    //   } else {
    //     const num = currentPage.value - 2;
    //     teachResourceEight.length = 0;
    //     const startIndex = 8 * num;
    //     const endIndex = startIndex + 8;
    //     teachResourceEight.push(
    //       ...teachResourceList.slice(startIndex, endIndex)
    //     );
    //     currentPage.value--;
    //   }
    // };
    // // 下一页
    // const handleNext = () => {
    //   if (currentPage.value === totalPage.value) {
    //     message.value = "已经是最后一页";
    //     ElMessage.warning({
    //       offset: 200,
    //       message: message.value,
    //       type: "warning",
    //     });
    //   } else {
    //     teachResourceEight.length = 0;
    //     const startIndex = 8 * currentPage.value;
    //     let endIndex;
    //     if (startIndex + 8 > teachResourceList.length) {
    //       endIndex = teachResourceList.length;
    //     } else {
    //       endIndex = startIndex + 8;
    //     }
    //     teachResourceEight.push(
    //       ...teachResourceList.slice(startIndex, endIndex)
    //     );
    //     currentPage.value++;
    //   }
    // };
    //  点击按钮切换到下一页
    const next = _.debounce(() => {
      logger.info(
        "点击按钮切换到下一页函数里拿到的当前页数是===>",
        currentPage.value + 1
      );
      if (currentPage.value === totalPage.value) {
        message.value = "已经是最后一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slideNext();
        // currentPage.value++;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);
    //  点击按钮切换到上一页
    const previous = _.debounce(() => {
      logger.info(
        "点击按钮切换到上一页函数里拿到的当前页数是===>",
        currentPage.value - 1
      );
      if (currentPage.value === 1) {
        message.value = "已经是第一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slidePrev();
        // currentPage.value--;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);
    const goToMaterialPpt = (pptUrl) => {
      router.push({
        path: "/courseWarePpt",
        query: {
          pptUrl,
          goBackUrl: "teachCourseWareCartoon",
        },
      });
    };
    // 卡片点击事件
    const handleCardClick = (e) => {
      const courseware = e.courseware;
      let type;
      let url;
      if (courseware || courseware === null) {
        type = courseware?.type;
        url = courseware?.url;
      } else {
        type = e.type;
        url = e.url;
      }
      if (!type) {
        getResourceInfo({ id: e.id });
        const length = breadcrumbList.length;
        breadcrumbList.push({
          id: e.id,
          current_id: length,
          name: e.name,
        });
      } else if (type === "ppt") {
        handleSavePageContext();
        goToMaterialPpt(url);
      } else {
        showResourceRef.value.handleShowResource(
          type,
          true,
          url,
          toRaw(courseware)
        );
      }
    };
    const handleSearch = _.debounce(() => {
      mySwiper.value.touches.diff = 0;
      // 查找
      if (!searchContent.value) {
        getResourceInfo();
      } else {
        getResourceInfo({ searchContent: searchContent.value });
      }
      breadcrumbList.splice(1);
      // 选择类型或搜索之后判断边界值
      logger.info("currentPage====>", currentPage.value);
      logger.info("totalPage====>", totalPage.value);
      if (currentPage.value === 1 && totalPage.value !== 1) {
        leftBorderFlag.value = false;
        rightBorderFlag.value = true;
      } else if (currentPage.value === 1 && totalPage.value === 1) {
        leftBorderFlag.value = false;
        rightBorderFlag.value = false;
      } else {
        leftBorderFlag.value = true;
        rightBorderFlag.value = true;
      }
      logger.info(
        "我在函数里判断完了",
        currentPage.value,
        totalPage.value,
        leftBorderFlag.value,
        rightBorderFlag.value
      );
    }, 500);
    const handleGoToLogin = () => {
      router.push({
        // path: "/home",
        path: "/login",
      });
    };
    const handleAndroidGoToHome = () => {
      logger.info("通知安卓返回");
      reloadData.value = true;
      // window.WebViewJavascriptBridge.callHandler(
      //   "closeTeachMaterial", // native中注册时的key值
      //   null // native中注册方法中的传入的数据
      // );
      // 现在不在该页面向Android去发送隐藏的方法，改为到blank页面去发送
      router.replace({
        path: "/blank",
        query: {
          skinType: 0,
        },
        // path: "/login",
      });
    };

    // 判断是否已经保存了检索筛选条件并初始化页面
    const handleInitDataByContext = () => {
      let params = {};
      const contextBread = contextInfo.value?.breadcrumbList || [];
      const contextName = contextInfo.value?.searchContent || undefined;
      // 存在上下文保存的筛选条件
      if (contextBread && contextBread.length > 1) {
        breadcrumbList.length = 0;
        contextBread.forEach((item) => {
          breadcrumbList.push(item);
        });
        params.id = breadcrumbList[breadcrumbList.length - 1]?.id;
      }
      // 名字检索
      if (contextName) {
        params.searchContent = contextName;
        searchContent.value = contextName;
      }
      const startPage = contextInfo.value.currentPage;

      contextInfo.value = {};
      return { params, startPage };
    };

    // 重新加载数据
    const init = () => {
      reloadData.value = false;
      searchContent.value = "";
      breadcrumbList.length = 0;
      breadcrumbList.push({ id: -1, current_id: 0, name: "我的课件" });
      showResourceRef.value.reload();

      const { params, startPage } = handleInitDataByContext();
      getResourceInfo(params, startPage);
    };
    const onSwiper = (swiper) => {
      logger.info("swiper", swiper);
    };
    const onSlideChange = () => {
      logger.info("当前页码是===>", currentPage.value);
    };
    //  页面切换到上一页
    const onSlidePrevTransitionEnd = () => {
      const currentActiveIndex = mySwiper.value.activeIndex;
      const nextPage = currentPage.value - 1;
      const nextPageMOD = nextPage % 3;
      const currentPageMOD = currentActiveIndex % 3;
      if (nextPageMOD === currentPageMOD) {
        mySwiper.value.touches.diff = 0;
        logger.info("breadcrumbList===>", breadcrumbList);
        logger.info("当前窗口是在==>", mySwiper.value.activeIndex);
        logger.info(
          "页面切换到上一页函数里拿到的当前页数是===>",
          currentPage.value - 1
        );
        currentPage.value--;
        if (currentPage.value !== 1) {
          getPrevData(currentPage.value - 1);
        }
      }
      // getPrevData(currentPage.value - 1, params);
    };
    //  页面切换到下一页
    const onSlideNextTransitionEnd = () => {
      logger.info("breadcrumbList===>", breadcrumbList);
      if (!firstLoading.value) {
        const currentActiveIndex = mySwiper.value.activeIndex;
        const nextPage = currentPage.value + 1;
        const nextPageMOD = nextPage % 3;
        const currentPageMOD = currentActiveIndex % 3;
        if (nextPageMOD === currentPageMOD) {
          mySwiper.value.touches.diff = 0;
          logger.info(
            "页面切换到下一页函数里拿到的当前页数是===>",
            currentPage.value + 1
          );
          currentPage.value++;
          if (currentPage.value !== totalPage.value) {
            getNextData(currentPage.value + 1);
          }
        }
        // getNextData(currentPage.value + 1, params);
      }
    };
    //  切换时获取下一页数据的函数
    const getNextData = async (page) => {
      logger.info(
        "在这拿到面包屑和搜索框的值===>",
        breadcrumbList,
        searchContent.value
      );
      if (breadcrumbList.length === 1) {
        if (!searchContent.value) {
          const nextData = await getMyCourseWare({
            parent_id: 0,
            per_page: 8,
            page: page,
          });
          if ((currentPage.value + 1) % 3 === 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...nextData.data.items);
          }
          if ((currentPage.value + 1) % 3 === 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...nextData.data.items);
          }
          if ((currentPage.value + 1) % 3 === 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...nextData.data.items);
          }
        } else {
          const nextData = await getMyCourseWareSearch({
            name: searchContent.value,
            per_page: 8,
            page: page,
          });
          if ((currentPage.value + 1) % 3 === 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...nextData.data.items);
          }
          if ((currentPage.value + 1) % 3 === 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...nextData.data.items);
          }
          if ((currentPage.value + 1) % 3 === 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...nextData.data.items);
          }
        }
      } else {
        const nextData = await getMyCourseWare({
          parent_id: breadcrumbList[breadcrumbList.length - 1].id,
          per_page: 8,
          page: page,
        });
        if ((currentPage.value + 1) % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...nextData.data.items);
        }
        if ((currentPage.value + 1) % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...nextData.data.items);
        }
        if ((currentPage.value + 1) % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...nextData.data.items);
        }
      }
    };
    //  切换时获取上一页数据的函数
    const getPrevData = async (page) => {
      logger.info("在这拿到面包屑===>", breadcrumbList);
      if (breadcrumbList.length === 1) {
        if (!searchContent.value) {
          const nextData = await getMyCourseWare({
            parent_id: 0,
            per_page: 8,
            page: page,
          });
          if ((currentPage.value - 1) % 3 === 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...nextData.data.items);
          }
          if ((currentPage.value - 1) % 3 === 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...nextData.data.items);
          }
          if ((currentPage.value - 1) % 3 === 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...nextData.data.items);
          }
        } else {
          const nextData = await getMyCourseWareSearch({
            name: searchContent.value,
            per_page: 8,
            page: page,
          });
          if ((currentPage.value - 1) % 3 === 1) {
            teachResourceEight.length = 0;
            teachResourceEight.push(...nextData.data.items);
          }
          if ((currentPage.value - 1) % 3 === 2) {
            middleTeachResourceEight.length = 0;
            middleTeachResourceEight.push(...nextData.data.items);
          }
          if ((currentPage.value - 1) % 3 === 0) {
            lastTeachResourceEight.length = 0;
            lastTeachResourceEight.push(...nextData.data.items);
          }
        }
      } else {
        const nextData = await getMyCourseWare({
          parent_id: breadcrumbList[breadcrumbList.length - 1].id,
          per_page: 8,
          page: page,
        });
        if ((currentPage.value - 1) % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...nextData.data.items);
        }
        if ((currentPage.value - 1) % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...nextData.data.items);
        }
        if ((currentPage.value - 1) % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...nextData.data.items);
        }
      }
    };
    onBeforeRouteLeave((to, from, next) => {
      if (to.name === "Home") {
        reloadData.value = true;
      }
      next();
    });
    onMounted(() => {
      reloadData.value = false;
      handleGetPageContext();
      init();
      initSwiper();
      firstLoading.value = false;
    });
    onActivated(() => {
      if (isAndroid.value) {
        logger.info("通知android显示");
        window.WebViewJavascriptBridge.callHandler(
          "loadSuccess", // native中注册时的key值
          null // native中注册方法中的传入的数据
        );
      }
      if (reloadData.value) {
        init();
      }
    });
    watch(
      () => currentPage.value,
      () => {
        logger.info("我监听了", leftBorderFlag.value, rightBorderFlag.value);
        if (currentPage.value === 1) {
          leftBorderFlag.value = false;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else {
          leftBorderFlag.value = true;
          logger.info("这里开启循环", leftBorderFlag.value);
        }
      },
      { immediate: true }
    );
    watch(
      () => mySwiper.value,
      _.debounce(() => {
        logger.info("在这看触摸距离", mySwiper.value.touches.diff);
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX > 0 &&
          mySwiper.value.touches.diff > 0 &&
          currentPage.value === 1
        ) {
          message.value = "已经是第一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX < 0 &&
          mySwiper.value.touches.diff < 0 &&
          currentPage.value === totalPage.value
        ) {
          message.value = "已经是最后一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
      }, 200),
      { deep: true }
    );
    watch(
      () => currentPage.value,
      () => {
        logger.info("我监听了", leftBorderFlag.value, rightBorderFlag.value);
        logger.info("总页数", totalPage.value);
        if (currentPage.value === totalPage.value) {
          rightBorderFlag.value = false;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else {
          rightBorderFlag.value = true;
          logger.info("这里开启循环", leftBorderFlag.value);
        }
      }
    );
    return {
      teachResourceEight,
      breadcrumbList,
      currentPage,
      totalPage,
      searchContent,
      showResourceRef,
      noData,
      mySwiper,
      getNextData,
      getPrevData,
      onSlidePrevTransitionEnd,
      onSlideNextTransitionEnd,
      onSwiper,
      onSlideChange,
      middleTeachResourceEight,
      lastTeachResourceEight,
      leftBorderFlag,
      rightBorderFlag,
      firstLoading,
      noDataMsg,
      next,
      previous,
      initSwiper,
      init,
      bindIcon,
      // handlePrevious,
      // handleNext,
      handleSearch,
      handleGoToLogin,
      handleCardClick,
      handleBreadcrumbClick,
      handleAndroidGoToHome,
    };
  },
});
